<template>
    <v-main>
        <section class="main">
          <v-container
            style="max-width: 1280px; position: relative; overflow:hidden;"
          >
            <div class="main-logo">
              <img src="/img/main-black/logo-newlvl-y.png" class="logo-newlvl-y" />
            </div>
            <div class="block-autorize text-center">
                <v-btn large class="btn-main login-btn mr-sm-7 mr-2" :to="{name: 'login', query: { partner: this.$route.query.partner }}">Войти</v-btn>
                <v-btn large class="btn-main mr-0" :to="{name: 'register', query: { partner: this.$route.query.partner }}">Регистрация</v-btn>
            </div>
            <div class="main-reg mt-13">
              <img class="arrow-w-top" src="/img/main-black/arrow-w-top.png" />
              <p class="p-reg mb-0">
                  Современные инструменты для сетевика профессионала
                </p>
            </div>

            <h2 class="h2-main">
              Новый уровень <br />
              твоего роста
            </h2>

            <div class="inst-title">
              <h2 class="num-nine">
                <animated-number 
                  :value="value" 
                  :formatValue="formatToPrice"
                  :duration="1500"
                />
              </h2>
              <h2 class="h2-nine">
                эффективных <br />
                инструментов
              </h2>
            </div>

            <h2 class="h2-title mt-8 text-center">Ты получишь?</h2>

            <div class="mt-5 what-robot">
              <div>
                <p class="p-what-main">
                    <img class="img-check-what" src="/img/main-black/check-w.png" />
                    <span>Автоматизацию и ускорение роста твоего бизнеса</span>
                </p>

                <p class="p-what-main">
                    <img class="img-check-what" src="/img/main-black/check-w.png" />
                    <span>НОВЫЕ ТЕХНОЛОГИИ ПРИВЛЕЧЕНИЯ ПАРТНЕРОВ И КЛИЕНТОВ</span>
                </p>

                <p class="p-what-main">
                    <img class="img-check-what" src="/img/main-black/check-w.png" />
                    <span>УПАКОВАННЫЕ МЕТОДЫ РЕКРУТИНГА И ПРОДАЖ</span>
                </p>

                <p class="p-what-main">
                    <img class="img-check-what" src="/img/main-black/check-w.png" />
                    <span>ПОЛНЫЙ НАБОР САЙТОВ ПО ТОП ПРОДУКТАМ LR</span>
                </p>
                <p class="p-what-main">
                    <img class="img-check-what" src="/img/main-black/check-w.png" />
                    <span>СОБСТВЕННУЮ РЕФЕРАЛЬНУЮ ПРОГРАММУ</span>
                </p>
                <p class="p-what-main">
                    <img class="img-check-what" src="/img/main-black/check-w.png" />
                    <span>ЛИЧНЫЙ КАБИНЕТ С ВОЗМОЖНОСТЬЮ ПОЛУЧЕНИЯ ЗАЯВОК</span>
                </p>
                <div class="video-box">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/KTNF1QAFs5k?si=xcvypQV5HqVOPYkw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  
                </div>
                <div class="mt-15" style="text-align: center;">
                  <button class="btn-y" @click="scrollToMe">Получить доступ</button>
                </div>
              </div>                
              <img class="robot-back" src="/img/main-black/robot-back.png" />
            </div>

            <div class="mt-5">
                <p class="p-reg">Подробнее смотри ниже</p>
                <v-img style="margin: 0 auto;" src="/img/main-black/arrow-w-bottom.png" max-width="50px" />
            </div>

            <div class="mt-7 inst-robot">
                <div class="inst-about-block">
                    <img src="/img/main-black/viz-icon.png" />
                    <div class="inst-about-text">
                        <h3>Личная страница-визитка</h3>
                        <p class="mb-0">Страница о тебе с фото и контактами, блок с дополнительной информацией и призывом к действию (аналог таплинка)</p>
                        <p class="p-free">Бесплатно после регистрации</p>
                    </div>
                </div>
                <div class="inst-about-block">
                    <img src="/img/main-black/biz-icon.png" />
                    <div class="inst-about-text">
                        <h3>Бизнес-презентация</h3>
                        <p>Страница о сути бизнеса, которую ты можешь настроить под себя и рассказать о своих результатах</p>
                    </div>
                </div>
                <div class="inst-about-block">
                  <img src="/img/main-black/virtual-icon.png" />
                  <div class="inst-about-text">
                    <h3>Виртуальный наставник</h3>
                    <p>Полностью автоматизированный, современный запуск твоего новичка</p>
                  </div>
                </div>
                <div class="inst-about-block">
                    <img src="/img/main-black/leed-icon.png" />
                    <div class="inst-about-text">
                        <h3>Лид-бот</h3>
                        <p>Бот-помощник, который ведет диалог за тебя и в зависимости от ответов рассказывает про продукт или про бизнес</p>
                    </div>
                </div>
                <div class="inst-about-block">
                    <img src="/img/main-black/btest-icon.png" />
                    <div class="inst-about-text">
                        <h3>Тест по бизнесу</h3>
                        <p>Вопросы, актуализирующие необходимость иметь дополнительный доход. Используется для привлечения новых партнеров]</p>
                    </div>
                </div>
                <div class="inst-about-block">
                  <img src="/img/main-black/builder-icon.png" />
                  <div class="inst-about-text">
                    <h3>Рост команды</h3>
                    <p>Инструмент увеличивающий количество партнеров в твоей команде</p>
                  </div>
                </div>
                <div class="inst-about-block">
                  <img src="/img/main-black/sales-icon.png" />
                  <div class="inst-about-text">
                    <h3>Большой ЛТО</h3>
                    <p>Инструмент увеличивающий ЛТО партнеров</p>
                  </div>
                </div>
                <div class="inst-about-block">
                    <img src="/img/main-black/start-icon.png" />
                    <div class="inst-about-text">
                        <h3>Варианты стартов</h3>
                        <p class="mb-0">Подробное описание вариантов старта и выгоды для кандидатов</p>
                        <p class="p-free">Бесплатно после регистрации</p>
                    </div>
                </div>
                <div class="inst-about-block">
                  <img src="/img/main-black/activation-icon.png" />
                  <div class="inst-about-text">
                    <h3>Сайт по запуску и активации</h3>
                    <p>Модульный сайт с пошаговой информацией для запуска новичка</p>
                  </div>
                </div>
                <div class="inst-about-block">
                    <img src="/img/main-black/htest-icon.png" />
                    <div class="inst-about-text">
                        <h3>Тест по здоровью</h3>
                        <p>Онлайн-тест, выявляющий наиболее уязвимые системы организма, на которые нужно обратить внимание. Используется для привлечения новых клиентов</p>
                    </div>
                </div>
                <div class="inst-about-block">
                    <img src="/img/main-black/aloe-icon.png" />
                    <div class="inst-about-text">
                        <h3>ГЕЛИ ALOE VERA</h3>
                        <p>СТРАНИЦА С ОПИСАНИЕМ ПОЛЬЗЫ ГЕЛЕЙ, ИХ СОСТАВА, ОБЛАСТИ ПРИМЕНЕНИЯ И ДОЗИРОВКИ ПРИ РАЗЛИЧНЫХ ЗАБОЛЕВАНИЯХ</p>
                    </div>
                </div>
                <div class="inst-about-block">
                    <img src="/img/main-black/be-icon.png" />
                    <div class="inst-about-text">
                        <h3>Капля 5 в 1</h3>
                        <p>Страница с описанием пользы бьюти эликсира для работы с кандидатами</p>
                    </div>
                </div>
                <div class="inst-about-block">
                    <img src="/img/main-black/zg-icon.png" />
                    <div class="inst-about-text">
                        <h3>Zeitgard</h3>
                        <p>Страница с описанием бьюти устройства и его возможностей для ежедневного ухода и увеличения дохода</p>
                    </div>
                </div>
                <div class="inst-about-block">
                    <img src="/img/main-black/avbox-icon.png" />
                    <div class="inst-about-text">
                        <h3>ALOE VERA BOX</h3>
                        <p>СТРАНИЦА С ОПИСАНИЕМ СОСТАВА КАЖДОГО КОМПОНЕНТА АПТЕЧКИ</p>
                    </div>
                </div>
                <div class="inst-about-block">
                  <img src="/img/main-black/gifts-icon.png" />
                  <div class="inst-about-text">
                    <h3>Подарки</h3>
                    <p>Варианты наборов подарков под разные праздники</p>
                  </div>
                </div>
                <div class="inst-about-block">
                    <img src="/img/main-black/aroma-icon.png" />
                    <div class="inst-about-text">
                        <h3>АРОМАМАСЛА</h3>
                        <p>Страница С ОПИСАНИЕМ МАСЕЛ, ИХ СВОЙСТВ И ОБЛАСТИ ПРИМЕНЕНИЯ</p>
                    </div>
                </div>
                <div class="inst-about-block">
                  <img src="/img/main-black/rec-health-icon.png" />
                  <div class="inst-about-text">
                    <h3>Рекомендации по заболеваниям</h3>
                    <p>Инструкции и дозировки по применению БАДов</p>
                  </div>
                </div>
                <div class="inst-about-block">
                  <img src="/img/main-black/online.png" />
                  <div class="inst-about-text">
                    <h3>Масштабирование онлайн</h3>
                    <p>Эффективная работа в социальных сетях</p>
                  </div>
                </div>
                <div class="inst-about-block">
                  <img src="/img/main-black/star-health-icon.png" />
                  <div class="inst-about-text">
                    <h3>Марафон ЗОЖ и коррекции веса StarHealth</h3>
                    <p>Практический курс по здоровью и бадам</p>
                  </div>
                </div>
                
                <h2 class="h2-help">Система newlvl помогла сотням партнеров выйти на более высокие квалификации</h2>
                <div class="video-box">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/8avNOx8W-cI?si=EXe8Fwy7DX61WwDB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <img class="robot-bottom" src="/img/main-black/robot.png" />
            </div>            
          </v-container>
        </section>
        <section class="tarifs">
          <v-container
            style="max-width: 1280px; position: relative; overflow:hidden;"
          >
            <!-- <h2 class="h2-tarif mb-5" id="oplata">Тарифы</h2>
            <p class="p-dostup">Доступ к 18 инструментам:</p>

            <v-row class="row-instruments">
              <v-col cols="12" sm="6"> 
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Страница-визитка</span>
                </p>
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Бизнес-презентация</span>
                </p>
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Виртуальный наставник</span>
                </p>
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Лид-бот</span>
                </p>
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Тест по бизнесу</span>
                </p>
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Рост команды</span>
                </p>
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Большой ЛТО</span>
                </p>                
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Варианты стартов</span>
                </p>   
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Сайт по запуску и активации</span>
                </p>                          
              </v-col>
              <v-col cols="12" sm="6">                
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Тест по здоровью</span>
                </p> 
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Гели Алое Вера</span>
                </p>
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Капля 5 в 1</span>
                </p> 
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Zeitgard</span>
                </p> 
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Алое Вера Box</span>
                </p>
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Подарки</span>
                </p>
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Аромамасла</span>
                </p>
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Рекомендации по заболеваниям</span>
                </p> 
                <p class="p-dostup-check">
                    <img src="/img/check-black.png" />
                    <span>Масштабирование онлайн</span>
                </p> 
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col cols="12" sm="3" class="block-one-year">
                <img class="skobka-left" src="/img/main-black/skobka-left.png" />
                <p class="p-one-year">1 год</p>                        
                <p class="p-cost-one-year-old">
                    <img class="old-price-img" src="/img/new_main/old-price.png" />
                    12 000 &#8381;
                </p>
                <p class="p-cost-one-year">6 000 &#8381;</p>
                <img class="skobka-right" src="/img/main-black/skobka-right.png" />
                <p class="p-samiy">Самый выгодный</p>
                <p class="price-500">500 руб в месяц</p>
                <img class="accent-img" src="/img/main-black/accent-red.png" />                    
              </v-col>
              <v-col cols="12" sm="3" style="text-align: center;" class="block-six">
                <p class="p-oplata-mes">6 мес</p>
                <p class="p-price-oplata-old">
                    <img class="old-price-img-oplata" src="/img/new_main/old-price.png" />
                    6 000 &#8381;
                </p>
                <p class="p-price-oplata">3 500 &#8381;</p>
                <p class="p-oplata-vigoda">
                    Твоя выгода <br />
                    2 500 &#8381;
                </p>
              </v-col>
              
              <v-col cols="12" sm="3" style="text-align: center;" class="block-three">
                <p class="p-oplata-mes">3 мес</p>
                <p class="p-price-oplata-old">
                    <img class="old-price-img-oplata" src="/img/new_main/old-price.png" />
                    3 000 &#8381;
                </p>
                <p class="p-price-oplata">2 000 &#8381;</p>
                <p class="p-oplata-vigoda">
                    Твоя выгода <br />
                    1 000 &#8381;
                </p>
              </v-col>

              <v-col cols="12" sm="3" style="text-align: center;" class="block-month">
                <p class="p-oplata-mes">1 мес</p>
                <p class="p-price-oplata-1000">1 000 &#8381;</p>
                <p class="p-oplata-vigoda mob">
                    &nbsp; <br />
                    &nbsp;
                </p>
              </v-col>
            </v-row> -->

            <!-- <p class="p-attention mt-5">! Внимание !</p>                
            <p class="p-open-dostup">Для оплаты необходимо ВОЙТИ в личный кабинет</p> -->
            <p class="p-open-dostup" id="oplata">Для получения доступа необходимо зарегистрироваться</p>
            <div class="block-autorize text-center mt-5 mb-7">
              <!-- <v-btn large class="btn-bottom login-btn mr-sm-7 mr-2" :to="{name: 'login', query: { partner: this.$route.query.partner }}">Войти</v-btn> -->
              <v-btn large class="btn-bottom mr-0" :to="{name: 'register', query: { partner: this.$route.query.partner }}">Регистрация</v-btn>
            </div>
            <div class="video-box">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/cB2er9RknXc?si=33o2Nw5gPO_GHMDo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </v-container>
        </section>
        <section class="footer-land">
          <v-container
            style="max-width: 1280px; position: relative; overflow:hidden;"
          >
            <p class="mt-7">
              Все инструменты NewLevel доступны только для команды StarTrack от 250 ЛТО. 
            </p>            
            <div class="mt-1" style="text-align: center;">
              <p class="mb-5">Для информации по получению доступа обратитесь к своему вышестоящему лидеру от МЛГ и выше.</p>
              <p class="mb-5">Если не нашли ответ на нужный вопрос, обратитесь к куратору:</p>
            </div> 
            <div class="telega-support mt-3 mb-5">                    
              <a
                  style="text-decoration: none"
                  href="https://t.me/Elena_Pahomova"
                  target="_blank"
              >
                  <img src="/img/main-black/tg-gold.png" />
              </a>
            </div>
            <!-- <div class="mt-1" style="text-align: center;">
              <p class="mb-5">Время работы техподдержки:</p>
              <p class="mb-5">пн-пт с 10 до 17 мск,</p>
              <p class="mb-10">сб, вс - выходной</p>
            </div>                 -->
          </v-container>
        </section>
    </v-main>
</template>

<script>
import AnimatedNumber from "animated-number-vue";
export default {
    components: {
        AnimatedNumber
    },
    name: "Landing",
    data: () => ({
        value: 21,
    }),
    metaInfo: {
        title: 'newlvl.net',
        meta: [
            { vmid: 'description', property: 'description', content: 'Командный сайт newlvl. Инструменты для сетевого бизнеса' },
            { vmid: 'og:title', property: 'og:title', content: 'newlvl.net' },
            { vmid: 'og:description', property: 'og:description', content: 'Командный сайт newlvl. Инструменты для сетевого бизнеса' },
            { vmid: 'og:image', property: 'og:image', content: '/img/newlvl_logo.png' },
        ],
    },
    methods: {
        scrollToMe () {
            let scrollBottom = document.getElementById("oplata")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        formatToPrice(value) {
            return `${value.toFixed(0)}`;
        }
    }
}
</script>

<style scoped>
@font-face {
  font-family: "Intro";
  src: url('/fonts/IntroDemo-BlackCAPS.otf');
}
.main {
  background: url("/img/main-black/bg-main-land.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.v-main {
  font-family: "Intro";
    background-color: #141414;
}
.main-logo {
  position: absolute;
  left: 30px;
  top: 30px;
}
.video-box {  
  text-align: center;
  margin-top: 40px !important;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}
.video-box  iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  outline-width: 0;
}

.logo-newlvl-y {    
    max-width: 180px;
    width: calc(60px + (180 - 80) * ((100vw - 320px) / (1280 - 320)));    
}
.btn-main {
  background-color: #fff !important;
  color: #141414 !important;
  font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1280 - 320)));
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 30px !important;
  border-radius: 10px;
}
.btn-bottom {
  background-color: #141414 !important;
  color: #fff !important;
  font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1280 - 320)));
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 30px !important;
  border-radius: 10px;
}
.block-autorize {
    margin-top: 40px;
}
.main-reg {
    position: relative;
}
.p-reg {
    color: #fff;
    text-transform: uppercase;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));
    text-align: center;
    font-weight: bolder;
    margin-bottom: 10px;
}
.arrow-w-top {
    position: absolute;
    max-width: 40px;
    right: 35%;
    top: -45px;
    transform: rotate(360deg);
}
.h2-main {
    font-size: calc(24px + (66 - 24) * ((100vw - 320px) / (1280 - 320)));
    text-transform: uppercase;
    margin-top: 60px;
    color: #fee88e;
    line-height: 100%;
    padding-left: 20px;
}
.num-nine {
  font-size: calc(60px + (160 - 60) * ((100vw - 320px) / (1280 - 320)));
  color: #fff;
  font-weight: 600;
  display: inline-block;
  padding-left: 20px;
}
.h2-nine {
  font-size: calc(18px + (54 - 18) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  line-height: 100%;
  display: inline-block;
  padding-left: 20px;
}
.h2-title {
  font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1280 - 320)));
  color: #fee88e;
}
.p-what-main {
  font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1280 - 320)));
    margin-bottom: 10px;
    color: #fff;
}
.p-what-main span {
    padding-left: 50px;
    display: block;
    padding-top: 5px;
    margin-bottom: 30px;
}
.img-check-what {
    max-width: 40px;
    position: absolute;
}
.btn-y {
  font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1280 - 320)));
  background-color: #fee88e;
  color: #141414;
  border-radius: 10px;
  padding: 5px 15px;
}
.what-robot {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}
.what-robot div {
  max-width: 1000px;
  margin: 0 auto;
}
.robot-back {
    left: 0px;
    bottom: 0;
    position: absolute;
    max-width: 650px;
    z-index: -1;
    opacity: 0.4;
}
.inst-robot {
  position: relative;
  z-index: 1;
}
.robot-bottom {
  right: 0px;
  bottom: 0;
  position: absolute;
  max-width: 650px;
  z-index: -1;
  opacity: 0.4;
}
.inst-about-block {
    margin-bottom: 50px;
}
.inst-about-block img {
    max-width: 100px;
    position: absolute;
}
.inst-about-text {
    padding-left: 140px;
}
.inst-about-text h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1280 - 320)));
}
.inst-about-text p {
  color: #fff;
  text-transform: uppercase;
  font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));
}
.p-free {
  font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));
  color: #fee88e !important;
}
.tarifs {
  background-color: #fff;
}
.h2-help {
    text-transform: uppercase;
    text-align: center;
    margin-top: 150px;
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1280 - 320)));
    color: #fff;
    margin-bottom: 50px;
}
.h2-tarif {
    text-transform: uppercase;
    text-align: center;
    color: #111;
    font-size: calc(36px + (100 - 36) * ((100vw - 320px) / (1280 - 320)));
}
.p-dostup {
  font-size: calc(18px + (34 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 60px;
  font-weight: bold;
}
.p-dostup-check {
   margin-bottom: 30px; 
   position: relative;
}
.p-dostup-check img {
  top: -5px;
  max-width: 35px;
  position: absolute;
}
.p-dostup-check span {
    padding-left: 45px;
    display: block;
    text-transform: uppercase;
    font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1280 - 320)));
}
.footer-land p {
  text-transform: uppercase;
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1280 - 320)));
  color: #9b9b9b;
  text-align: center;
}
.footer-land .telega-support img {
  width: 70px;
}
.block-one-year {
    position: relative;
    /* margin-top: 20px; */
    text-align: center;
}
.skobka-left {
    max-width: 24px;
    position: absolute;
    left: 10%;
    top: 10px;
}
.p-one-year {
  background-color: #333;
  display: inline-block;
  color: #fff;
  padding: 5px 15px;
  font-size: 24px;
  font-weight: normal;
  margin-top: -20px;
}
.p-cost-one-year-old {
    font-size: 28px;
    margin-top: 40px;
    position: relative;
}
.old-price-img {
    position: absolute;
    max-width: 70px;
    left: 30%;
}
.p-cost-one-year {
    font-size: 32px;
    font-weight: 500;
    margin-top: 50px;
}
.skobka-right {
    max-width: 24px;
    position: absolute;
    right: 10%;
    top: 10px;
}
.p-samiy {
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 20px;
    background-color: #333;
}
.price-500 {
  font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}
.accent-img {
  max-width: 150px;
}
.p-oplata-mes {
    text-align: center;
    background-color: #333;
    display: inline-block;
    color: #fff;
    padding: 5px 15px;
    font-size: 24px;
    font-weight: normal;
    margin-top: -20px;
}
.p-price-oplata-old {
    position: relative;
    text-align: center;
    font-size: 28px;
    margin-top: 40px;
}
.old-price-img-oplata {
    position: absolute;
    max-width: 70px;
}
.p-price-oplata {
    font-size: 32px;
    text-align: center;
    font-weight: 500;
    margin-top: 50px;
}
.p-price-oplata-1000 {
  font-size: 28px;
    text-align: center;
    margin-top: 40px;
}
.p-oplata-vigoda {
    font-size: 18px;
    text-align: center;
    font-weight: 500;
}
.p-attention {
    color: #333;
    font-size: 28px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
}
.p-open-dostup {
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
}
.telega-support {
    text-align: center;
}
.aloe-bottom-right {
    bottom: 0;
    right: 0;
    position: absolute;
    max-width: 150px;
}
@media (min-width: 1280px) {
  .btn-main {
    font-size: 32px;
  }
  .btn-bottom {
    font-size: 32px;
  }
  .p-reg {
    font-size: 24px;
  }
  .h2-main {
    font-size: 66px;
  }
  .num-nine {
    font-size: 160px;
  }
  .h2-nine {
    font-size: 54px;
  }
  .h2-title {
    font-size: 42px;
  }
  .p-what-main {
    font-size: 28px;
  }
  .btn-y {
    font-size: 28px;
  }
  .inst-about-text h3 {
    font-size: 28px;
  }
  .inst-about-text p {
    font-size: 22px;
  }
  .p-free {
    font-size: 22px !important;
  }
  .h2-help {
    font-size: 22px;
  }
  .h2-tarif {
    font-size: 100px;
  }
  .p-dostup {
    font-size: 34px;
  }
  .p-dostup-check span {
    font-size: 30px;
  }
  .footer-land p {
    font-size: 20px;
  }
}
@media(max-width: 1000px) {
  .arrow-w-top {
    right: 32%;
  }  
}
@media(max-width: 800px) {
  .main-logo {
    top: 15px;
    left: 15px;
  }
  .main .block-autorize {
    margin-top: 20px;
  }
  .arrow-w-top {
    max-width: 40px;
  }
  .h2-main {
    margin-top: 40px;
  }
  .h2-help {
    margin-top: 100px;
    margin-bottom: 30px;
  }
}
@media(max-width: 650px) {
  .main-logo {
    position: relative;
    text-align: center;
    top: 0;
    left: 0;
  }
}
@media(max-width: 600px) {
  .h2-main {
    text-align: center;
    padding: 0;
  }
  .inst-title {
    text-align: center;
    padding: 0;
  }
  .row-instruments {
    padding-left: 20px;
  }
  .block-one-year, .block-six, .block-three, .block-month {
    margin-bottom: 40px;
  }
  .old-price-img {
    max-width: 80px;
    top: -5px;
    left: 40%;
  }
  .p-price-oplata-old {
    margin-top: 20px;
  }
  .old-price-img-oplata {
    max-width: 80px;
    top: -5px;
  }
  .p-dostup-check {
    margin-bottom: 20px;
  }
  .p-oplata-vigoda.mob {
    display: none;
  }
  .p-price-oplata {
    margin-top: 30px;
  }
  .p-price-oplata-1000 {
    margin-top: 20px;
  }
  .h2-help {
    margin-top: 80px;
  }
  .btn-main.login-btn {
    margin-bottom: 10px !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 480px) {
  .inst-about-block img {
    max-width: 80px;
  }
  .inst-about-text {
    padding-left: 100px;
  }
  .h2-help {
    margin-top: 50px;
  }
}
@media (max-width: 480px) {
  .inst-about-block img {
    max-width: 60px;
  }
  .inst-about-text {
    padding-left: 80px;
  }
}
@media (max-width: 360px) {
    
}
</style>